import React from 'react';
import { BackgroundImg } from './BackgroundImg';
import microImg from './images/translation.png';
import pcImg from './images/pc.png';

const ComputerSetup = () => {
  return (
    <div className="computer-setup">
      <div className="setup-info">
        <h2 className="title">
          Как организовать рабочее пространство для комфортного проведения
          урока?
        </h2>
        <p className="undertitle">
          Наши занятия проходят в онлайн формате. Соответственно для их
          <br />
          успешного проведения необходима техническая подготовка.
        </p>
        <div className="subtitle subtitle2">
          <img src={pcImg} alt="" />
          <div>
            Заниматься рекомендуем с <b>компьютера</b> или <b>ноутбука</b>. Если
            такой возможности нет, можно использовать планшет.
          </div>
        </div>
        <h3 className="subtitle">
          <img src={microImg} alt="" />
          <div>Наушники с микрофоном</div>
        </h3>
        <p>
          Для того, чтобы шумы не отвлекали во время урока и сторонние шумы не
          мешали, рекомендуем подготовить наушники с микрофоном. Также будет
          здорово, если у вас есть камера, благодаря ей тренер сможет следить,
          чтобы ученик не отвлекался.
        </p>
      </div>
      <BackgroundImg />
    </div>
  );
};

export default ComputerSetup;
