import React from 'react';
import ComputerSetup from './ComputerSetup';
import Equipment from './Equipment';

const SetupBlock = () => {
  return (
    <section className="setup-block container">
      <ComputerSetup />
      <Equipment />
    </section>
  );
};

export default SetupBlock;
