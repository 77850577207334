import React from 'react';
import { BackgroundImg } from './BackgroundImg';
import checkpointImg from './images/checkpoint.png';

const SentimentBlock = () => {
  return (
    <section className="sentiment-block container">
      <BackgroundImg />
      <div className="content">
        <h2 className="title">Как настроиться на урок?</h2>
        <p className="undertitle">
          Организация рабочего пространства — это половина успеха. Вторая часть
          подготовки — эмоциональный настрой. Для того, чтобы помочь ребенку и с
          этим, следуйте простым рекомендациям:
        </p>
        <div className="checkpoint">
          <img src={checkpointImg} alt="" />
          <div>Расскажите как будет проходить пробный урок по шахматам.</div>
        </div>
        <div className="checkpoint">
          <img src={checkpointImg} alt="" />
          <div>
            Продолжительность пробного занятия — 60 минут. Позаботьтесь о том,
            чтобы в это время никто не отвлекал ученика и не было запланировано
            никаких важных дел вплотную к уроку.
          </div>
        </div>
        <div className="checkpoint">
          <img src={checkpointImg} alt="" />
          <div>
            Посоветуйте ребенку во время занятия не стесняться делиться идеями и
            завать вопросы, если что-то непонятно. Мы всегда приветствуем
            активную включенность в работу наших юных мыслителей.
          </div>
        </div>
        <div className="checkpoint">
          <img src={checkpointImg} alt="" />
          <div>
            Расскажите ученику, что во время урока можно в любой момент позвать
            тренера, для этого нужно отправить в чат цифру “1” или “+” — это
            равнозначно поднятой руке на обычном занятии в школе. Так тренер
            будет видеть, что возник вопрос и быстро подключится.
          </div>
        </div>
        <div className="checkpoint">
          <img src={checkpointImg} alt="" />
          <div>
            Поощряйте самостоятельность ребенка во время урока. Позвольте ему
            пробовать, ошибаться, находить ошибки под чутким присмотром опытного
            куратора.
          </div>
        </div>
      </div>
    </section>
  );
};

export default SentimentBlock;
