import React from 'react';
import pointerImg from './images/pointer.png';
import userImg from './images/add-user.png';
import wifiImg from './images/wifi.png';

const Equipment = () => {
  return (
    <div className="setup-list">
      <h3 className="subtitle">
        <img src={pointerImg} alt="" />
        <div>Регистрация на платформе lichess.org</div>
      </h3>
      <p>
        Для вашего комфорта мы записали небольшую{' '}
        <a
          className="link"
          href="https://www.youtube.com/watch?v=zTM7-dQLky0"
          rel="noreferrer"
          target="_blank"
        >
          видеоинструкцию
        </a>
        . Нужна предварительная регистрация, чтобы во время урока не пришлось
        тратить время на организационные моменты.
      </p>
      <h3 className="subtitle">
        <img src={userImg} alt="" />
        <div>Выслать данные аккаунта Skype</div>
      </h3>
      <p>
        За несколько часов до урока необходимо выслать логин вашего аккаунта
        Skype. Благодаря этому тренер легко найдет ученика в Skype и занятие
        начнется без заминок.
      </p>
      <h3 className="subtitle">
        <img src={wifiImg} alt="" />
        <div>Проверить качество связи</div>
      </h3>
      <p>
        За 10-15 минут до начала занятия рекомендуем проверить качество связи.
        Желательно, чтобы скорость интернет-соединения была не менее 1Мбит/сек.
      </p>
      <p className="ready-text">
        Все готово? Супер! В назначенное время вам осталось только принять
        звонок от тренера в Skype и наслаждаться учебным процессом.
      </p>
    </div>
  );
};

export default Equipment;
