import React from 'react';

type IItem = {
  title: string | JSX.Element;
  content: string | JSX.Element;
  color?: string;
};
const accordionItems: IItem[] = [
  {
    title: 'Если при подготовке к уроку возникли трудности?',
    content: `Самое главное — не переживайте. Свяжитесь, пожалуйста, с администратором и объясните в чем дело. Наша команда приложит все усилия, чтобы помочь вам в решении возникшей проблемы.`,
    color: 'blue',
  },
  {
    title: 'Если не получается подключиться к занятию в назначенное время?',
    content: `Это не проблема. Предупредите, пожалуйста, администратора заранее о том, что ученик не сможет посетить пробное занятие. Мы с удовольствием поможет подобрать вам более удобное время для переноса.`,
    color: 'red',
  },
  {
    title: 'Что ещё нужно знать, для подготовки к пробному уроку?',
    content: (
      <div>
        <p>
          А больше ничего. Правильно организованное рабочее пространство и ваше
          хорошее настроение — залог успешного первого занятия.
        </p>
        <p>
          Если остались какие-либо вопросы, свяжитесь с администратором. Мы с
          удовольствием вам поможем.
        </p>
      </div>
    ),
    color: 'yellow',
  },
];

export default accordionItems;
