import React from 'react';
import { BackgroundImg } from './BackgroundImg';
import skypeImg from './images/skype.png';
import clockImg from './images/clock.png';
import chessImg from './images/chess-board.png';
import chatImg from './images/chat.png';

const PassingBlock = () => {
  return (
    <section className="passing-block container">
      <BackgroundImg />
      <div className="point-list">
        <h2 className="title">Как проходит пробный урок?</h2>
        <h3 className="point-title">
          <img src={skypeImg} alt="Skype" />
          <div>
            Тренер <b className="primary">связывается</b> с учеником через Skype
          </div>
        </h3>
        <p className="pass-text">
          Все занятия по шахматам проходят в онлайн-формате на платформе
          lichess.org через Skype.
        </p>
        <h3 className="point-title">
          <img src={clockImg} alt="Часы" />
          <div>
            Занятие <b className="secondary">длится</b> 60 минут
          </div>
        </h3>
        <p className="pass-text">
          Пробное пройдет в форме индивидуального занятия продолжительностью 60
          минут. Такой формат, как показывает опыт, самый комфортный. За это
          время тренер с ребенком познакомятся и разберут новый материал в
          удобном темпе. Самое главное - такая продолжительность позволяет
          сохранять хороший уровень концентрации на протяжении всего урока.
        </p>
        <h3 className="point-title">
          <img src={chessImg} alt="" />
          <div>
            Определение <b className="brown">уровня</b> ученика
          </div>
        </h3>
        <p className="pass-text">
          Во время занятия педагог определяет текущий уровень ученика и с учетом
          этого подбирает материалы.
        </p>
        <h3 className="point-title">
          <img src={chatImg} alt="" />
          <div>
            <b className="danger">Обратнаю связь</b> о ходе занятия и
            проделанной работе
          </div>
        </h3>
        <p className="pass-text">
          После урока администратор отправит вам обратную связь проведения
          занятия и о его результатах. Педагог предоставит рекомендации на
          будущее по длительности и форме регулярных занятий для реализации
          потенциала и достижения поставленных целей.
        </p>
      </div>
    </section>
  );
};

export default PassingBlock;
