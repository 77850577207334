import React from 'react';
import { BackgroundImg } from './BackgroundImg';

const ConclusionBlock = () => {
  return (
    <section className="conclusion-block container">
      <BackgroundImg />
      <div className="block-info">
        <h2 className="title">
          Подготовка — это половина успеха в любом деле.
        </h2>
        <p className="undertitle">
          Спасибо, что внимательно ознакомились с информацией. После урока будем
          благодарны за обратную связь. Ваш отзыв — наше вознаграждение за все
          усилия по организации и проведению занятий. Желаем вам хорошего дня и
          отличного настроения! До встречи на уроке!
        </p>
      </div>
    </section>
  );
};

export default ConclusionBlock;
