import React from 'react';
import Accordion from 'components/Accordion';
import accordion_items from './accordion_items';

const AccordionBlock = () => {
  return (
    <section className="accordion-block container">
      <h2 className="title">Часто задаваемые вопросы</h2>
      <Accordion items={accordion_items} />
    </section>
  );
};

export default AccordionBlock;
