import React from 'react';
import { BackgroundImg } from './BackgroundImg';

const IntroBlock = () => {
  return (
    <section className="intro-block container">
      <div className="trial-intro">
        <h1 className="title">Пробное занятие</h1>
        <div className="subtitle">
          Первое занятие в новой школе это всегда немного волнительно. Чтобы всё
          прошло успешно, нужна небольшая подготовка. Здесь вы найдете полезные
          советы и практические рекомендации, которые помогут организовать
          рабочее пространство и подготовить ребенка к пробному уроку.
        </div>
      </div>
      <BackgroundImg />
    </section>
  );
};

export default IntroBlock;
