import React from 'react';
import { SEO } from '../../components/SEO';
import IntroBlock from './IntroBlock';
import PassingBlock from './PassingBlock';
import SetupBlock from './SetupBlock';
import SentimentBlock from './SentimentBlock';
import ConclusionBlock from './ConclusionBlock';
import AccordionBlock from './AccordionBlock';
import './styles.scss';

const AboutTrialLesson = () => {
  return (
    <main className="page about-trial-lesson">
      <SEO
        title="О пробном занятии"
        description="Как проходит занятие? Что необходимо? Узнайте об этих и других вопросах."
      />
      <IntroBlock />
      <PassingBlock />
      <SetupBlock />
      <SentimentBlock />
      <AccordionBlock />
      <ConclusionBlock />
    </main>
  );
};

export default AboutTrialLesson;
